import React from "react"
import Layout from "../components/Layout"
import Hero from "../components/ui/Hero/Hero"
import Section from "../components/ui/Section/Section"

const Agb = ({ data }) => {
    return (
        <Layout
            crumbs={[
                { label: "Home", url: "" },
                { label: "AGBs", url: "agb" },
            ]}
        >
            <Hero title="Allgemeinen Geschäftsbedingungen" />
            <Section>
                <div className="content">
                    <p>
                        <b>1. Geltungsbereich</b>
                        <br />
                        F&uuml;r alle Bestellungen &uuml;ber unseren Online-Shop
                        gelten die nachfolgenden AGB.
                        <br />
                        <br />
                        <b>2. Vertragspartner, Kundendienst</b>
                        <br />
                        Der Kaufvertrag kommt zustande mit HIS Print &amp;
                        Service GmbH &amp; Co. KG. Weitere Informationen zu uns
                        finden Sie im Impressum. Sie erreichen unseren
                        Kundendienst f&uuml;r Fragen, Reklamationen und
                        Beanstandungen 8:00-18:00 unter der Telefonnummer
                        08283/998100.
                        <br />
                        <br />
                        <b>3. Vertragsabschluss</b>
                        <br />
                        Mit Einstellung der Produkte in den Online-Shop geben
                        wir ein verbindliches Angebot zum Vertragsschluss
                        &uuml;ber diese Artikel ab. Der Vertrag kommt zustande,
                        indem Sie durch Anklicken des Bestellbuttons das Angebot
                        &uuml;ber die im Warenkorb enthaltenen Waren annehmen.
                        Unmittelbar nach dem Absenden der Bestellung erhalten
                        Sie noch einmal eine Best&auml;tigung per E-Mail.
                        <br />
                        Ein bindender Vertrag kann auch bereits zuvor wie folgt
                        zustande kommen:
                        <br />
                        <ul>
                            <li>
                                Wenn Sie die Zahlungsart PayPal gewählt haben,
                                kommt der Vertrag zum Zeitpunkt Ihrer
                                Bestätigung der Zahlungsanweisung an PayPal
                                zustande.
                            </li>
                        </ul>
                        <br />
                        Bedingung f&uuml;r einen wirksamen Vertragsschluss ist
                        stets, dass der Bestellvorgang mit Absenden der
                        Bestellung abgeschlossen wird.
                        <br />
                        <br />
                        <b>4. Versandkosten</b>
                        <br />
                        Zuz&uuml;glich zu den angegebenen Produktpreisen kommen
                        noch Versandkosten hinzu. N&auml;heres zur H&ouml;he der
                        Versandkosten erfahren Sie bei den Angeboten.
                        <br />
                        <br />
                        <b>5. Bezahlung</b>
                        <br />
                        Die Zahlung erfolgt per Vorkasse, Bankeinzug, PayPal.
                        Soweit aufgrund Vereinbarungen nicht mit den hier
                        aufgelisteten Bezahlmethoden bezahlt werden muss, sind
                        Rechnungen sofort nach Erhalt ohne Abzug zahlbar, sofern
                        nicht schriftlich andere Zahlungsbedingungen vereinbart
                        wurden.
                    </p>
                    <br />
                    <br />
                    <ul>
                        <li>
                            <b>Vorkasse</b>
                            <br />
                            Bei Auswahl der Zahlungsart Vorkasse nennen wir
                            Ihnen unsere Bankverbindung in der
                            Auftragsbest&auml;tigung und liefern die Ware nach
                            Zahlungseingang.
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <b>Nachnahme</b>
                            <br />
                            Bei Auswahl der Zahlungsart Nachnahme fallen zzgl. 5
                            Euro als Kosten an. Zusätzlich sind 4 Euro direkt an
                            den Zusteller zu entrichten.{" "}
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <b>Paypal</b>
                            <br />
                            Sie bezahlen den Rechnungsbetrag &uuml;ber den
                            Online-Anbieter Paypal. Sie m&uuml;ssen
                            grunds&auml;tzlich dort registriert sein bzw. sich
                            erst registrieren, mit Ihren Zugangsdaten
                            legitimieren und die Zahlungsanweisung an uns
                            best&auml;tigen (Ausnahme ggf. Gastzugang). Weitere
                            Hinweise erhalten Sie beim Bestellvorgang.
                        </li>
                    </ul>
                    <br />
                    <p>
                        <b>6. Barzahlung bei Selbstabholung</b>
                        <br />
                        Sie haben grunds&auml;tzlich die M&ouml;glichkeit der
                        Abholung bei HIS Print &amp; Service GmbH &amp; Co. KG,
                        Zur Sch&ouml;nhalde 1, 89352 Ellzee, Bayern, zu den
                        nachfolgend angegebenen Gesch&auml;ftszeiten: Mo.-Do.
                        8&deg;&deg;-17&deg;&deg; Uhr Fr.
                        (8&deg;&deg;-13&deg;&deg; Uhr)
                        <br />
                        <br />
                        <b>7. Packstation</b>
                        <br />
                        Wir liefern nicht an Packstationen.
                        <br />
                        <br />
                        <b>8. Eigentumsvorbehalt</b>
                        <br />
                        Die Ware bleibt bis zur vollst&auml;ndigen Bezahlung
                        unser Eigentum.
                        <br />
                        <br />
                        <b>9. Transportsch&auml;den</b>
                        <br />
                        Werden Waren mit offensichtlichen Transportsch&auml;den
                        angeliefert, so reklamieren Sie solche Fehler bitte
                        m&ouml;glichst sofort beim Zusteller und nehmen Sie
                        bitte unverz&uuml;glich Kontakt zu uns auf. Die
                        Vers&auml;umung einer Reklamation oder Kontaktaufnahme
                        hat f&uuml;r Ihre gesetzlichen Anspr&uuml;che und deren
                        Durchsetzung, insbesondere Ihre
                        Gew&auml;hrleistungsrechte keinerlei Konsequenzen. Sie
                        helfen uns aber, unsere eigenen Anspr&uuml;che
                        gegen&uuml;ber dem Frachtf&uuml;hrer bzw.
                        Transportversicherung geltend machen zu k&ouml;nnen.
                        <br />
                        <br />
                        <b>10. Widerrufsbelehrung</b>
                        <br />
                        Nachfolgend erhalten Sie eine Belehrung &uuml;ber die
                        Voraussetzung und Folgen des gesetzlichen
                        Widerrufsrechtes bei Versandbestellungen.
                    </p>
                    <br />
                    <br />
                    <div style={{background: "#EFEFEF", border: "1px solid #aaa", padding: "0 15px 15px 15px", marginBottom: 15}}>
                        <p>
                            <strong>Widerrufsbelehrung</strong>
                        </p>
                        <p>
                            <strong>Widerrufsrecht </strong>
                        </p>
                        <p>
                            Sie k&ouml;nnen Ihre Vertragserkl&auml;rung
                            innerhalb von 14 Tagen ohne Angabe von Gr&uuml;nden
                            in Textform (z.B. Brief, Fax, E-Mail) oder - wenn
                            Ihnen die Sache vor Fristablauf &uuml;berlassen wird
                            - auch durch R&uuml;cksendung der Sache widerrufen.
                            Die Frist beginnt nach Erhalt dieser Belehrung in
                            Textform, jedoch nicht vor Eingang der Ware beim
                            Empf&auml;nger (bei der wiederkehrenden Lieferung
                            gleichartiger Waren nicht vor Eingang der ersten
                            Teillieferung) und auch nicht vor Erf&uuml;llung
                            unserer Informationspflichten gem&auml;&szlig;
                            Artikel 246 &sect; 2 in Verbindung mit &sect; 1
                            Absatz 1 und 2 EGBGB sowie unserer Pflichten
                            gem&auml;&szlig; &sect; 312g Absatz 1 Satz 1 BGB in
                            Verbindung mit Artikel 246 &sect; 3 EGBGB. Zur
                            Wahrung der Widerrufsfrist gen&uuml;gt die
                            rechtzeitige Absendung des Widerrufs oder der Sache.
                            Der Widerruf ist zu richten an:
                        </p>
                        <p>
                            HIS Print &amp; Service GmbH &amp; Co. KG
                            <br />
                            Zur Sch&ouml;nhalde 1<br />
                            89352 Ellzee
                            <br />
                            E-Mail: reklamation@his-print-service.de
                        </p>
                        <p>
                            <strong>Widerrufsfolgen </strong>
                        </p>
                        <p>
                            Im Falle eines wirksamen Widerrufs sind die
                            beiderseits empfangenen Leistungen
                            zur&uuml;ckzugew&auml;hren und ggf. gezogene
                            Nutzungen (z.B. Zinsen) herauszugeben. K&ouml;nnen
                            Sie uns die empfangene Leistung sowie Nutzungen
                            (z.B. Gebrauchsvorteile) nicht oder teilweise nicht
                            oder nur in verschlechtertem Zustand
                            zur&uuml;ckgew&auml;hren beziehungsweise
                            herausgeben, m&uuml;ssen Sie uns insoweit Wertersatz
                            leisten. F&uuml;r die Verschlechterung der Sache und
                            f&uuml;r gezogene Nutzungen m&uuml;ssen Sie
                            Wertersatz nur leisten, soweit die Nutzungen oder
                            die Verschlechterung auf einen Umgang mit der Sache
                            zur&uuml;ckzuf&uuml;hren ist, der &uuml;ber die
                            Pr&uuml;fung der Eigenschaften und der
                            Funktionsweise hinausgeht. Unter &quot;Pr&uuml;fung
                            der Eigenschaften und der Funktionsweise&quot;
                            versteht man das Testen und Ausprobieren der
                            jeweiligen Ware, wie es etwa im Ladengesch&auml;ft
                            m&ouml;glich und &uuml;blich ist.
                            Paketversandf&auml;hige Sachen sind auf unsere
                            Gefahr zur&uuml;ckzusenden. Sie haben die
                            regelm&auml;&szlig;igen Kosten der R&uuml;cksendung
                            zu tragen, wenn die gelieferte Ware der bestellten
                            entspricht und wenn der Preis der
                            zur&uuml;ckzusendenden Sache einen Betrag von 40
                            Euro nicht &uuml;bersteigt oder wenn Sie bei einem
                            h&ouml;heren Preis der Sache zum Zeitpunkt des
                            Widerrufs noch nicht die Gegenleistung oder eine
                            vertraglich vereinbarte Teilzahlung erbracht haben.
                            Andernfalls ist die R&uuml;cksendung f&uuml;r Sie
                            kostenfrei. Nicht paketversandf&auml;hige Sachen
                            werden bei Ihnen abgeholt. Verpflichtungen zur
                            Erstattung von Zahlungen m&uuml;ssen innerhalb von
                            30 Tagen erf&uuml;llt werden. Die Frist beginnt
                            f&uuml;r Sie mit der Absendung Ihrer
                            Widerrufserkl&auml;rung oder der Sache, f&uuml;r uns
                            mit deren Empfang.
                        </p>
                        <p>
                            <strong>Ende der Widerrufsbelehrung</strong>
                        </p>
                        <p>
                            Das Widerrufsrecht besteht nicht bei
                            Fernabsatzvertr&auml;gen
                        </p>
                        <ul>
                            <li>
                                zur Lieferung von Waren, die nach
                                Kundenspezifikation angefertigt werden oder
                                eindeutig auf die pers&ouml;nlichen&nbsp;
                                Bed&uuml;rfnisse zugeschnitten sind oder die
                                aufgrund ihrer Beschaffenheit nicht f&uuml;r
                                eine R&uuml;cksendung geeignet sind oder schnell
                                verderben k&ouml;nnen oder deren Verfallsdatum
                                &uuml;berschritten w&uuml;rde
                            </li>
                        </ul>
                    </div>
                    <p>
                        <br />
                        <b>
                            11. Kostentragungsvereinbarung bei Aus&uuml;bung des
                            Widerrufsrechtes
                        </b>
                        <br />
                        Wir machen von der M&ouml;glichkeit Gebrauch, Ihnen im
                        Rahmen der Aus&uuml;bung des in der vorstehenden
                        Belehrung wiedergegebenen gesetzlichen Widerrufsrechtes
                        wie folgt die Kosten der R&uuml;cksendung aufzuerlegen:
                        Sie haben die regelm&auml;&szlig;igen Kosten der
                        R&uuml;cksendung zu tragen, wenn die gelieferte Ware der
                        bestellten entspricht und wenn der Preis der
                        zur&uuml;ckzusendenden Ware einen Betrag von 40,00 Euro
                        nicht &uuml;bersteigt, oder wenn Sie bei einem
                        h&ouml;heren Preis der Sache zum Zeitpunkt des
                        Widerrufes noch nicht die Gegenleistung oder eine
                        vertraglich vereinbarte Teilzahlung erbracht haben.
                        <br />
                        <br />
                        <b>12. Vertragstextspeicherung</b>
                        <br />
                        Wir speichern den Vertragstext und senden Ihnen die
                        Bestelldaten und unsere AGB per E-Mail zu. Die AGB
                        k&ouml;nnen Sie jederzeit auch hier auf dieser Seite
                        einsehen und herunterladen. Ihre vergangenen
                        Bestellungen k&ouml;nnen Sie in unserem Kunden-Login
                        einsehen.
                        <br />
                        <br />
                        <b>13. Vertragssprache</b>
                        <br />
                        Die f&uuml;r den Vertragsschluss zur Verf&uuml;gung
                        stehende Sprache ist Deutsch.
                        <br />
                        <br />
                        <b>14 .Schlussbestimmungen</b>
                        <br />
                        Sind Sie Unternehmer, dann gilt deutsches Recht unter
                        Ausschluss des UN-Kaufrechts.
                        <br />
                        <br />
                        Sind Sie Kaufmann im Sinne des Handelsgesetzbuches,
                        juristische Person des öffentlichen Rechts oder
                        öffentlich-rechtliches Sondervermögen, ist
                        ausschließlicher Gerichtsstand für alle Streitigkeiten
                        aus Vertragsverhältnissen zwischen uns und Ihnen unser
                        Geschäftssitz.
                        <br />
                        <br />
                        <br />
                        <br />
                        HIS Print &amp; Service GmbH &amp; Co. KG
                    </p>
                    <br />
                    <br />
                    Stand April 2024
                </div>
            </Section>
        </Layout>
    )
}

export default Agb